<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <!-- <h4 class="card-title"><b>NUEVA AREA</b></h4> -->
            </template>
            <h4 class="px-4 py-4"><b>Editar Area</b></h4>
            <div class="row px-5">
              <div class="col-xl-6">
                <div>
                  <label for=" " class="form-label">Nombre Area</label>
                  <input
                    required
                    v-model="item.descripcion"
                    placeholder="Escriba el Nombre de la Sucursal..."
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-xl-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Sucursal</label>
                  <b-form-select
                    @change.native="actualizaDepto($event)"
                    v-model="sucursal"
                    required
                    text-field="descripcion"
                    value-field="id"
                    :options="ListSucursales"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        -- Seleccionar sucursal --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>

              <div class="col-xl-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Empresa</label>
                  <b-form-select
                    :disabled="usuario.rol.nivel != 4 ? true : false"
                    required
                    v-model="item.empresa.id"
                    @change.native="actualizaSucursal($event)"
                    value-field="id"
                    text-field="nombre"
                    :options="ListEmpresas"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        -- Seleccionar empresa --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="mb-3">
                  <label for=" " class="form-label">Departamento</label>
                  <b-form-select
                    required
                    v-model="item.departamento.id"
                    value-field="id"
                    text-field="descripcion"
                    :options="ListDepartamento"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        -- Seleccionar departamento --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button
          primary
          icon
          animation-type="vertical"
          type="submit"
          class="mr-1"
        >
          <i class="fas fa-save pr-1"></i>
          Guardar Área
          <template #animate>
            <i class="fas fa-save"></i>
          </template>
        </vs-button>
        <vs-button
          danger
          icon
          animation-type="vertical"
          class="mx-1"
          @click="$router.push('/helex/Areas')"
        >
          <i class="fas fa-xmark pr-1"></i>
          Cancelar
          <template #animate>
            <i class="fas fa-xmark"></i>
          </template>
        </vs-button>
      </div>
    </form>
  </b-container>
</template>
<script>
//  import { core } from "../../../config/pluginInit";
export default {
  name: "DataTable",
  data() {
    return {
      id: "",
      selected: null,
      item: {
        empresa: {
          id: "",
        },
        departamento: {
          id: "",
        },
        sucursal: {
          id: "",
        },
      },
      ListEmpresas: [],
      ListSucursales: [],
      ListDepartamento: [],
      usuario: {},
      sucursal: "",
    };
  },
  async mounted() {
    try {
      console.log("mounted areas_editar");
      this.$isLoading(true);
      //   core.index();
      this.id = this.$route.params.id;

      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      //console.log("***************", this.usuario);
      await this.getData();
      //this.item.empresa.id = this.usuario.empresa.id;
      await this.getEmpresas();
      await this.getSucursalEmpresa(this.usuario.empresa.id);
      await this.getDepartamentoEmpresa();
      this.$isLoading(false);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async actualizaSucursal(e) {
      console.log(e.target.value);
      //this.sucursal = e.target.value;
      await this.getSucursalEmpresa(e.target.value);
    },
    async actualizaDepto(e) {
      console.log(e.target.value);
      this.sucursal = e.target.value;
      await this.getDepartamentoSucursal(this.sucursal);
    },
    async getDepartamentoSucursal() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Departamento/ListDepartamentoSucursal/" + this.sucursal,
        });
        this.ListDepartamento = res;
        console.log("departamentosooooooooooooo ", res);
      } catch (error) {
        this.ListDepartamento = {};
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Area/GetArea/" + this.id,
        });
        this.item = res;

        console.log("*****-- ", this.item);
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
    async getDepartamentoEmpresa() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Departamento/ListDepartamento/" + this.usuario.empresa.id,
        });
        this.ListDepartamento = res;
        console.log("departamentosooooooooooooo ", res);
        for await (let itv of this.ListDepartamento) {
          //console.log('itv', itv.id , ' ', this.item.departamento.id)
          if (itv.id == this.item.departamento.id) {
            this.sucursal = itv.sucursal.id;
          }
        }
        console.log("this.sucursal", this.sucursal);
        this.ListDepartamento = this.ListDepartamento.filter(
          (x) => x.sucursal.id == this.sucursal
        );
        console.log("se encontró suucrsal", this.ListDepartamento);
      } catch (error) {
        this.ListDepartamento = {};
        console.log("err", error);
      }
    },
    async getSucursalEmpresa(id) {
      //console.log(this.persona.empresa.id);
      try {
        //console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + id,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.ListSucursales = res;
        } else {
          this.ListSucursales = [];
        }
      } catch (error) {
        this.ListSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;

        //console.log("list", this.ListEmpresas);
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async saveData() {
      console.log("saving", this.item);
      try {
        this.item.EmpresaId = this.item.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        this.item.DepartamentoId = this.item.departamento.id;
        this.item.Estado = this.item.estado;

        let res = await this.$store.dispatch("hl_post", {
          path: "Area/Update/",
          data: this.item,
        });
        console.log("post empresas...", res);
        if (res) this.$router.push("/helex/Areas");
        else alert("Verificar");
      } catch (error) {
        //this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
